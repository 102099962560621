import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn } from '@src/components/styled';
import { BodyMedium, BodyMediumBold } from '@src/components/styled/Typography';
import { ReactComponent as ArrowIcon } from './arrow-icon.svg';
const DetailsContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
}));
const DetailsBlockContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.5,
    padding: `${props.theme.spacing_sizes.xs * 2.5}px ${props.theme.spacing_sizes.xs * 3}px`,
    boxSizing: 'border-box',
    backgroundColor: '#F8FAFF',
    borderRadius: 12,
}));
const DetailsTitle = styled(BodyMediumBold)(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.s,
    letterSpacing: '-0.224px',
    alignItems: 'center',
}));
const PrevValue = styled.span(props => ({
    color: props.theme.customColors.shades.primaryMidLight,
}));
const NewValue = styled.span(props => ({
    color: props.theme.palette.primary.main,
}));
const DetailsText = styled(BodyMedium)(props => ({
    letterSpacing: '-0.224px',
    color: props.theme.palette.text.primary,
}));
const DetailsBlock = ({ item }) => (_jsxs(DetailsBlockContainer, { children: [_jsxs(DetailsTitle, { children: [_jsx(PrevValue, { children: item.prev_value }), _jsx(ArrowIcon, {}), _jsx(NewValue, { children: item.new_value })] }), _jsx(DetailsText, { children: item.text })] }));
const ChangeTarifDetails = ({ list }) => (_jsx(DetailsContainer, { children: list.map((item, index) => _jsx(DetailsBlock, { item: item }, index)) }));
export default ChangeTarifDetails;
